<template>
  <b-card>
    <form-wrapper :validator="$v.form" v-if="!loading" class="av-tooltip tooltip-right-top">
      <b-row>
        <form-group name="title" md="6" :label="$t('project.bookName')">
          <b-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              v-on="listeners"
              v-model="form.title"
          />
        </form-group>
        <form-group name="publisher" md="6" :label="$t('project.publisher')">
          <b-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              v-on="listeners"
              v-model="form.publisher"
          />
        </form-group>
      </b-row>
      <b-row>
        <form-group name="authors" md="6" :label="$t('project.authors')">
          <add-author
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              v-on="listeners"
              v-model="form.authors"
          />
        </form-group>
        <form-group name="categories" md="6" :label="$t('project.categories')">
          <add-category
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              v-on="listeners"
              v-model="form.categories"
          />
        </form-group>
      </b-row>
      <b-row>
        <form-group name="year" md="6" :label="$t('project.publishDate')">
          <b-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              v-on="listeners"
              v-model="form.year"
          />
        </form-group>
        <form-group name="shelf" md="6" :label="$t('project.shelf')">
          <b-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              v-on="listeners"
              v-model="form.shelf"
          />
        </form-group>
      </b-row>

      <b-row>
        <form-group name="barcode" md="6" :label="$t('project.barcode')">
          <b-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              v-on="listeners"
              v-model="form.barcode"
          />
        </form-group>
        <form-group name="isLoanable" md="6" label="Ödünç Alınabilir">
          <b-checkbox
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              v-on="listeners"
              switch
              size="lg"
              v-model="form.isLoanable"
          />
        </form-group>
      </b-row>

      <b-row>
        <form-group name="description" xs="12" :label="$t('project.description')">
          <b-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              v-on="listeners"
              v-model="form.description"
          />
        </form-group>
      </b-row>
    </form-wrapper>
    <div class="text-center" v-if="loading">
      <b-spinner variant="primary" />
    </div>
    <template slot="footer">
      <b-button
          variant="primary"
          @click="submitForm"
          :disabled="$v.$anyError || saved || submitting"
          class="mr-1">
        {{ $t("common.save") }} <b-spinner small v-if="submitting" />
      </b-button>
    </template>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AddAuthor from "@/view/pages/admin/book/AddAuthor";
import AddCategory from "@/view/pages/admin/book/AddCategory";
import {integer, maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'project.adminPanel' },
      { title: 'project.bookTransactions' }
    ]);

    this.getBook();
    this.$v.$reset();
  },
  components: {
    AddAuthor,
    AddCategory
  },
  validations: {
    form: {
      title: { required },
      publisher: {},
      year: { integer },
      shelf: {},
      description: {},
      barcode: {
        required,
        maxLength: maxLength(6),
        minLength: minLength(6)
      },
      authors: {
        // required: (val) => {
        //   return val.length > 0
        // }
      },
      categories: {
        // required: (val) => {
        //   return val.length > 0
        // }
      }
    }
  },
  data() {
    return {
      modal: false,
      countries: [],
      form: {
        title: '',
        publisher: '',
        year: '',
        shelf: '',
        description: '',
        barcode: '',
        authors: [],
        categories: []
      },
      loading: true,
      submitting: false,
      saved: true
    };
  },
  methods: {
    async getBook() {
      try {
        this.loading = true;
        const { data } = await this.axios.get('lib/books/' + this.$route.params.id);
        this.form = data;
        setTimeout(() => {
          this.saved = true;
        }, 200);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async submitForm() {
      try {
        this.submitting = true;
        this.$v.$touch();
        if (this.$v.$anyError) {
          this.toast({ type: 'danger', message: 'validationError' });
          return false;
        }

        await this.axios.patch('lib/books/' + this.$route.params.id, this.form);
        this.$emit("refreshList");
        this.saved = true;
        this.toast({ message: 'updateSuccess', item: 'book' });

      } catch (e) {
        this.toast({ type: "danger", message: "updateError", item: "book" });
        console.log(e);
      } finally {
        this.submitting = false;
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.saved = false;
      },
      deep: true
    }
  }
};
</script>
